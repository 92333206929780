<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouvel objet métier</span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Nom:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Nom"
                  v-model="newObjet.name"
                  :class="{
                    'is-invalid':
                      $v.newObjet.name.$error && $v.newObjet.name.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.name.$dirty"
                >
                  {{
                    !$v.newObjet.name.required
                      ? "Champ obligatoire"
                      : !$v.newObjet.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newObjet.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Description:</label>
              </div>
              <div class="col-md-8">
                <b-form-textarea
                  type="text"
                  placeholder="Description"
                  v-model="newObjet.description"
                ></b-form-textarea>
              </div>
            </div>
            <div class="row my-3 align-items-center" id="add-page">
              <div class="col-md-4">
                <label class="font-weight-bold">Concept:</label>
              </div>
              <div class="col-md-8">
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newObjet.concept.$error && $v.newObjet.concept.$dirty,
                  }"
                >
                  <v-select
                    label="text"
                    placeholder="Concept"
                    v-model="newObjet.concept"
                    :options="conceptsList"
                    appendToBody
                    :reduce="({ value }) => value"
                    :class="{
                      'is-invalid':
                        $v.newObjet.concept.$error &&
                        $v.newObjet.concept.$dirty,
                    }"
                  ></v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.concept.$dirty"
                >
                  {{ !$v.newObjet.concept.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">DCP:</label>
              </div>
              <div class="col-md-8">
                <b-form-select
                  type="text"
                  placeholder="DCP"
                  disabled
                  v-model="newObjet.dcp"
                  :class="{
                    'is-invalid':
                      $v.newObjet.dcp.$error && $v.newObjet.dcp.$dirty,
                  }"
                  :options="['Oui', 'Non']"
                ></b-form-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.dcp.$dirty"
                >
                  {{ !$v.newObjet.dcp.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Sensible:</label>
              </div>
              <div class="col-md-8">
                <b-form-select
                  type="text"
                  placeholder="Sensible"
                  v-model="newObjet.sensible"
                  disabled
                  :class="{
                    'is-invalid':
                      $v.newObjet.sensible.$error &&
                      $v.newObjet.sensible.$dirty,
                  }"
                  :options="[0]"
                ></b-form-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.sensible.$dirty"
                >
                  {{
                    !$v.newObjet.sensible.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <b-tabs class="tab-solid tab-solid-primary mt-3">
        <b-tab active title="Attributs">
          <AttributTable :disabled="true" :attributs="[]" /> </b-tab
      ></b-tabs>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import AttributTable from "../components/AttributTable";

export default {
  components: {
    ModalActions,
    AttributTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newObjet: {
      name: "",
      description: "",
      dcp: "Non",
      sensible: 0,
      concept: " ",
    },
    attributs: {
      tableFields: [
        { key: "attributs", label: "Attributs", sortable: true },
        { key: "dcp", label: "DCP", sortable: true },
        { key: "sensible", label: "Sensible", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
    },
  }),
  validations: {
    newObjet: {
      name: {
        required,
        minLength: minLength(5),
      },
      dcp: {
        required,
      },
      sensible: {
        required,
      },
      concept: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("concept/fetchAllConcepts");
  },
  methods: {
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.$store.dispatch("objet/createObjet", this.newObjet).then(() => {
            this.submitingForm = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters("objet", ["OBJET"]),
    ...mapGetters("concept", ["CONCEPTS"]),
    conceptsList() {
      return this.CONCEPTS.map((concept) => ({
        text: concept.name,
        value: concept["@id"],
      }));
    },
  },
  watch: {
    OBJET() {
      this.submitingForm = false;

      Swal.fire({
        title: "L'objet est bien créé !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-business-object",
            params: {
              slug: this.OBJET.slug || "abcde",
              id: this.OBJET.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
